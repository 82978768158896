const motivation = {
  id: 'motivation',
  type: 'multiselect',
  maxOptions: 3,
  heading: `Thank you so much for your input,
            at FightCamp we're all about using feedback to
            improve (Like with our Punch Trackers!) and so we have one
            last question to help us improve all that we do at FightCamp`,
  question: 'What helped convince you the MOST to join FightCamp [Choose 3]?',
  options: [{
      id: 'Youtube Ad',
      label: 'Youtube Ad',
    },
    {
      id: 'FightCamp\'s Blog',
      label: 'FightCamp\'s Blog',
    },
    {
      id: 'FightCamp\'s YouTube Channel',
      label: 'FightCamp\'s YouTube Channel',
    },
    {
      id: 'FightCamp\'s Instagram Page',
      label: 'FightCamp\'s Instagram Page',
    },
    {
      id: 'FightCamp\'s Twitter',
      label: 'FightCamp\'s Twitter',
    },
    {
      id: 'FightCamp\'s TikTok',
      label: 'FightCamp\'s TikTok',
    },
    {
      id: 'FightCamp\'s Pinterest',
      label: 'FightCamp\'s Pinterest',
    },
    {
      id: 'FightCamp\'s Facebook Page',
      label: 'FightCamp\'s Facebook Page',
    },
    {
      id: 'FB Community Page',
      label: 'FB Community Page',
    },
    {
      id: 'Downloading the App',
      label: 'Downloading the App',
    },
    {
      id: 'Podcast Ad',
      label: 'Podcast Ad',
    },
    {
      id: 'Television Ad',
      label: 'Television Ad',
    },
    {
      id: 'Instagram Ad',
      label: 'Instagram Ad',
    },
    {
      id: 'Google Ad',
      label: 'Google Ad',
    },
    {
      id: 'Facebook Ad',
      label: 'Facebook Ad',
    },
    {
      id: 'Training Journal',
      label: 'Training Journal',
    },
    {
      id: 'Reviews',
      label: 'Reviews',
    },
    {
      id: 'Text Messages from FightCamp',
      label: 'Text Messages from FightCamp',
    },
    {
      id: 'Someone you know posted about their experience',
      label: 'Someone you know posted about their experience',
    },
    {
      id: 'Influencer / Celebrity I follow posted about their experience',
      label: 'Influencer / Celebrity I follow posted about their experience',
    },
    {
      id: 'FightCamp\'s Emails',
      label: 'FightCamp\'s Emails',
    },
    {
      id: 'Online Publication (Men\'s Health, etc)',
      label: 'Online Publication (Men\'s Health, etc)',
    },
    {
      id: 'Retail Store',
      label: 'Retail Store',
    },
    {
      id: 'FightCamp Brochure',
      label: 'FightCamp Brochure',
    },
    {
      id: 'Speaking with a FightCamp rep',
      label: 'Speaking with a FightCamp rep',
    },
    {
      id: 'Other',
      label: 'Other',
      inlineFollowup: {
        label: 'Please Explain',
      },
    },
  ],
};

module.exports = motivation;
