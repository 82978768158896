<template>
  <div>
    <ConfirmationView :url="appBranchUrl" />
  </div>
</template>
<script>
  import ConfirmationView from '@/shared/components/confirmation/Confirmation.vue';

  export default {
    name: 'PostPurchase',
    components: {
      ConfirmationView,
    },
    computed: {
      appBranchUrl() {
        return 'https://fightcamp.app.link/F81mhjFMPkb';
      },
    },
    beforeRouteEnter(_to, _from, next) {
      next(vm => {
        if (!vm.$store.state.checkout.confirmation) {
          vm.$router.push({ name: 'BizDev' });
        } else {
          next();
        }
      });
    },
    beforeRouteLeave(_to, _from, next) {
      next(vm => {
        if (!vm.$sore.state.survey.completed) {
          if (this.$store.state.survey.surveyCurrentPos > 0) {
            vm.$store.commit('GOTO_PREVIOUS_SLIDE');
            return next(false);
          }
          vm.$store.commit('COMPLETE_SURVEY');
          return next(false);
        }
        if (vm.$store.state.checkout.confirmation) {
          vm.$store.commit('setConfirmation', null);
        }
        window.location.reload(true);
        return next(false);
      });
    },
  };
</script>
