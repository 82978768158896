<template>
  <div class="experience-goaldonut">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 42 42"
      class="donut"
      fill="transparent">
      <circle
        class="donut-hole"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent" />
      <circle
        class="donut-ring"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        :stroke="content.bgcolor"
        stroke-width="10" />
      <circle
        class="donut-segment"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        :stroke="content.color"
        stroke-width="10"
        :stroke-dasharray="dasharray"
        :stroke-dashoffset="offset" />
      <g class="chart-text">
        <text
          x="50%"
          class="chart-label-top"
          :fill="content.color">
          {{ content.title }}
        </text>
        <text
          x="50%"
          y="50%"
          class="chart-number"
          :fill="content.fontcolor">
          {{ left }}
        </text>
        <text
          x="50%"
          y="50%"
          class="chart-label"
          :fill="content.bgcolor">
          {{ content.subtitle }}
        </text>
      </g>
      <transition name="fade">
        <g
          v-if="completed"
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd">
          <g
            id="Group"
            transform="translate(5.000000, 5.00000)"
            stroke="white">
            <circle
              id="Oval"
              cx="16"
              cy="16"
              r="16"
              :fill="content.color"
              :stroke="content.color"
              stroke-width="3" />
            <polyline
              id="Path"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              points="9 15.75 14.7857143 21.5 23.2857143 11" />
          </g>
        </g>
      </transition></svg>
  </div>
</template>

<script>
  export default {
    name: 'GoalDonut',
    props: {
      value: { type: Number, default: 0 },
      total: { type: Number, default: 0 },
      content: { type: Object, default: () => ({}) },
    },
    computed: {
      percent() {
        return this.value / this.total * 100;
      },
      offset() {
        return this.percent + 25;
      },
      left() {
        if (this.content.formatter) {
          return this.content.formatter(this.value, this.total);
        }
        return this.value;
      },
      dasharray() {
        return `${this.percent} ${100 - this.percent}`;
      },
      completed() {
        return this.value >= this.total;
      },
    },
  };
</script>

<style scoped lang="scss">
  .experience-goaldonut {
    position: relative;
  }

  .donut-segment {
    transition: all 0.5s ease;
  }

  .chart-text {
    font: 16px/1.4em "New-Grotesk-Square-FOUR", Arial, sans-serif;
    fill: $color-white;
    transform: translateY(0.25em);
  }

  .chart-number {
    font: 16px/1.4em "New-Grotesk-Square-FOUR", Arial, sans-serif;
    font-size: 1em;
    line-height: 1;
    text-anchor: middle;
    transform: translateY(0.1em);
  }

  .chart-label-top {
    font: 16px/1.4em "New-Grotesk-Square-FOUR", Arial, sans-serif;
    font-size: 0.25em;
    text-anchor: middle;
    transform: translateY(9px);
  }

  .chart-label {
    font: 16px/1.4em "New-Grotesk-Square-FOUR", Arial, sans-serif;
    font-size: 0.25em;
    text-anchor: middle;
    transform: translateY(6px);
  }
</style>
