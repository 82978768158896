<template>
  <SurveySlide :slide="slide">
    <ButtonRouter
      :to="slide.path"
      class="survey-slidethankyou-button">
      {{ slide.label }}
    </ButtonRouter>
  </SurveySlide>
</template>
<script>

  import ButtonRouter from '@/shared/components/ButtonRouter.vue';
  import SurveySlide from './Survey_Slide.vue';

  export default {
    name: 'Survey_SlideMessage',
    components: {
      ButtonRouter,
      SurveySlide,
    },
    props: {
      slide: {
        type: Object,
        default: () => ({}),
      },
    },
  };
</script>
<style scoped lang="scss">

.survey-slidethankyou {
  &-button {
    margin: 10px auto;
    max-width: 200px;
  }
}
</style>
