<template>
  <div v-if="!surveyCompleted && surveyCurrentSlide" class="survey">
    <div v-if="!loading" class="survey-content">
      <SurveyProgress v-if="!disableSurveyProgressUi" class="survey-progress" />
      <transition-group name="fade" mode="out-in">
        <component
          :is="getSlideComponent(slide)"
          v-for="(slide,pos) in surveySlides"
          v-show="pos === surveyCurrentPos"
          :key="slide.id + pos"
          :slide="slide"
          @onSubmit="next($event)"
          @onCompleted="completeSurvey()" />
      </transition-group>
    </div>
    <div v-else>
      <p>One Moment...</p>
    </div>
      <BetaModal v-if="displayBetaModal" />
  </div>
</template>

<script>
  import { mapMutations, mapGetters, mapActions } from 'vuex';
  import motivation from '@/shared/datasets/hdyhau/motivation';
  import SurveySlideSelect from '@/shared/components/survey/Survey_SlideSelect.vue';
  import SurveySlideInput from '@/shared/components/survey/Survey_SlideInput.vue';
  import SurveySlideList from '@/shared/components/survey/Survey_SlideList.vue';
  import SurveySlideThankYou from '@/shared/components/survey/Survey_SlideThankYou.vue';
  import SurveySlideMultiSelect from '@/shared/components/survey/Survey_SlideMultiSelect.vue';
  import SurveySlideMessage from '@/shared/components/survey/Survey_SlideMessage.vue';
  import SurveyProgress from '@/shared/components/survey/Survey_Progress.vue';
  import BetaModal from '@/shared/components/BetaModal.vue';

  function evaluateDeepness(obj, current = 0) {
    if (obj.followup) {
      return evaluateDeepness(obj.followup, current + 1);
    }

    if (obj.options) {
      return obj.options.reduce((acc, option) => {
        if (option.followup) {
          const test = evaluateDeepness(option.followup, current + 1);
          return (acc > test) ? acc : test;
        }
        return acc;
      }, current + 1);
    }
    if (obj.type !== 'thankyou') {
      return current + 1;
    }
    return current;
  }

  export default {
    name: 'Survey',
    components: {
      SurveyProgress,
      BetaModal,
    },
    props: {
      survey: {
        type: Object,
        default: () => ({}),
      },
      message: {
        type: String,
        default: '',
      },
      disableSurveyProgressUi: {
        type: Boolean,
        default: false,
      },
      disableLegacyThankYouPage: {
        type: Boolean,
        default: false,
      }
    },
    data: () => ({
      loading: true,
      motivationSurveyCompleted: false,
    }),
    computed: {
      ...mapGetters([
        'surveyCurrentPos',
        'surveySlides',
        'surveyCurrentSlide',
        'surveyCompleted']),

      showMessage() {
        if (this.surveyCurrentSlide) {
          return true;
        }
        return this.surveyCurrentSlide.type !== 'thankyou';
      },
      displayBetaModal() {
      const { $store } = this;
        return $store.state.survey.customerDeclinedBeta === null && $store.getters.qualifiesForBeta && !$store.state.survey.betaMessage;
      },
    },
    watch: {
      surveyCurrentPos(currentPos) {
        if (this.disableSurveyProgressUi) {
          this.$emit('progressChange', Math.floor((currentPos)) / evaluateDeepness(this.surveyCurrentSlide, currentPos));
        }
      },
    },
    methods: {
      ...mapMutations({
        nextSlide: 'GOTO_NEXT_SLIDE',
        completeSurvey: 'COMPLETE_SURVEY',
      }),
      ...mapActions(['createSurvey', 'updateSurvey']),

      getSlideComponent(slide) {
        if (slide.type === 'select') {
          return SurveySlideSelect;
        }

        if (slide.type === 'list') {
          return SurveySlideList;
        }

        if (slide.type === 'thankyou' && !this.$props.disableLegacyThankYouPage) {
          return SurveySlideThankYou;
        }

        if (slide.type === 'thankyou' && this.$props.disableLegacyThankYouPage) {
          return undefined;
        }

        if (slide.type === 'message') {
          return SurveySlideMessage;
        }

        if (slide.type === 'multiselect') {
          return SurveySlideMultiSelect;
        }

        return SurveySlideInput;
      },

      async next(event) {
        const showFollowup = !!event.followup;
        const showMotivationSurvey = !showFollowup && !this.motivationSurveyCompleted;
        const showEnd = !showFollowup && this.motivationSurveyCompleted;

        const sendUpdate = showFollowup || showMotivationSurvey;

        if (showFollowup) {
          this.nextSlide({
            ...event.followup,
            others: event.others,
            attribution: event.attribution,
          });
        }

        if (showMotivationSurvey) {
          this.nextSlide({
            ...motivation,
            bypassId: event.id,
            others: event.others,
            attribution: event.attribution,
          });
          this.motivationSurveyCompleted = true;
        }

        if (showEnd) {
          this.nextSlide({
            id: `${event.id} end`,
            attribution: event.attribution,
            type: 'thankyou',
            question: 'Thank You!',
            label: 'Go to Order',
          });
        }

        if (sendUpdate) {
          let details = event.value ? event.value : (event.others || [])
          try {
            await this.updateSurvey({
              value: event.id,
              details,
              attribution: event.attribution,
              status: showMotivationSurvey ? 'completed' : 'inprogress',
            });
          } catch (err) {
            console.log('error updating survey', err)
          }
        }
      },
    },
    created() {
      this.createSurvey(this.survey)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          console.log('Error creating survey', err)
          this.completeSurvey();
        });
    },
  };
</script>

<style scoped lang="scss">
.survey {
  margin: 20px auto;
  border-top: 1px solid $color-light-gray;
  max-width: 650px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  &-content {
    padding: 0 10px;
  }

  @media #{$tablet} {
    margin: 40px auto;
  }

  @media #{$desktop} {
    margin: 40px auto;
  }

  &-progress {
    margin-top: 20px;
  }

  &-message {
    margin: 30px 0 10px;

    &--hidden {
      color: $color-white;
    }
  }

  &-fade {
    &-enter-active {
      transition: all 0.3s ease;
    }

    &-leave-active {
      transition: all 0.5s ease;
    }

    &-enter {
      opacity: 0;
    }

    &-leave-to {
      opacity: 0;
    }
  }
}

</style>
