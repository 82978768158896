<template>
  <SurveySlide :slide="slide" v-track-view="'SurveyComplete'">
    <ButtonAction
      class="survey-slidethankyou-button"
      v-track-click="`Finished Survey and moved to Next Steps`"
      @click.native="$emit('onCompleted')">
      {{ slide.label }}
      <span />
    </ButtonAction>
  </SurveySlide>
</template>
<script>

  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import SurveySlide from './Survey_Slide.vue';

  export default {
    name: 'Survey_SlideThankYou',
    components: {
      ButtonAction,
      SurveySlide,
    },
    props: {
      slide: {
        type: Object,
        default: () => ({}),
      },
    },
  };
</script>
<style scoped lang="scss">

.survey-slidethankyou {
  &-button {
    margin: 10px auto;
    max-width: 200px;
  }
}
</style>
