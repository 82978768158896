<template>
  <div class="subupgrade__container fc-margin-y-6 md:fc-margin-y-10 fc-padding-x-2">
    <FCCard class="subupgrade__monthly fc-bg-eggshell fc-text-blue fc-text-center fc-padding-x-12 fc-flex fc-align-center">
      <h2>Monthly Membership</h2>
      <div class="fc-padding-bottom-8">
        <p class="fc-margin-top-0">$39/month (billed monthly)</p>
        <p class="fc-margin-y-0">All Annual Membership Features</p>
        <p class="fc-margin-y-0">Pay Month-to-Month</p>
      </div>
      <FCButton
        button-theme="button-secondary"
        custom-event-name="nextStep"
        v-track-click="'Confirmation_SubUpgrade_Monthly'"
        @nextStep="setUpgradeFormCompleted">
          Stay with Monthly
      </FCButton>
    </FCCard>
    <FCCard class="subupgrade__annual fc-bg-blue fc-text-center fc-text-white fc-padding-x-12 fc-flex fc-align-center sm:fc-padding-x-5">
      <h2>Annual Membership</h2>
      <div class="fc-margin-bottom-8">
        <p class="fc-margin-top-0">$35.10/month (billed annually)</p>
        <p class="fc-margin-y-0">10% Off Your Membership Price</p>
        <p class="fc-margin-y-0">Unlocked Interactivity & Workout Modes</p>
        <p class="fc-margin-y-0">Exclusive Badges & Achievements</p>
        <p class="fc-margin-y-0">Create Multiple User Profiles</p>
        <p class="fc-margin-y-0">Access to Solo & Community Challenges</p>
      </div>
      <FCButton
        button-theme="button-primary"
        custom-event-name="nextStep"
        v-track-click="'Confirmation_SubUpgrade_Annual'"
        @nextStep="openMembershipModal(membershipIds.ANNUAL)">
          Upgrade to Annual
      </FCButton>
    </FCCard>
    <client-only>
      <MembershipUpgradeModal />
    </client-only>
  </div>
</template>

<script>
import { FCCard, FCButton } from '@fc/angie-ui';
import { Actions } from '@/shared/store/actionsTypes';
import { MembershipIds } from '@/shared/constants/MembershipIds';

  export default {
    name: 'ConfirmationSubUpgrade',
    components: {
      FCCard,
      FCButton,
      MembershipUpgradeModal: () => import('../MembershipUpgradeModal.vue'),
    },
    computed: {
      customerId() {
        return this.$store.state.checkout.customerId;
      },
      shipping() {
        return this.$store.state.checkout.shipping;
      },
      membershipIds() {
        return MembershipIds;
      }
    },
    methods: {
      setUpgradeFormCompleted() {
        this.$store.commit('COMPLETE_UPGRADE_FORM');
      },
      openMembershipModal(type) {
        this.$store.dispatch(Actions.OPEN_MODAL, { component: 'membershipUpgradeModal', config: [{ type }]});
      },
    }
  }
</script>

<style lang="scss">
.subupgrade {
  &__container {
    display: grid;
    gap: 1rem;

    @media #{$tablet-up} {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__container-variant {
    @media #{$tablet-up} {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__monthly {
    order: 3;

    @media #{$tablet-up} {
      order: 1;
      margin: 2rem 0;
    }
  }

  &__6months {
    order: 2;

    @media #{$tablet-up} {
      order: 3;
      margin: 2rem 0;
    }
  }

  &__annual {
    order: 1;

    @media #{$tablet-up} {
      order: 2;
    }
  }
}
</style>