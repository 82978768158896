<template>
  <SurveySlide
    :slide="slide"
    skippable
    @onSubmit="$emit('onSubmit', $event);">
    <div class="survey-slideselect ">
      <ButtonAction
        v-for="(option, index) in getSurveryOptions"
        :key="option.id + index"
        class="survey-slideselect-option"
        secondary
        @click.native="$emit('onSubmit', option)">
        {{ option.label }}
      </ButtonAction>
    </div>
  </SurveySlide>
</template>
<script>
  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import SurveySlide from './Survey_Slide.vue';
  import randomizeOptions from '../../utils/surveryHelpers';

  export default {
    name: 'Survey_SlideSelect',
    components: {
      ButtonAction,
      SurveySlide,
    },
    props: {
      slide: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      getSurveryOptions() {
        return randomizeOptions(this.slide);
      },
    },
  };
</script>
<style scoped lang="scss">
.survey-slideselect {
  max-width: 600px;
  width: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &-option {
    margin: 5px;
    font-size: .75rem;
    padding: 8px 10px 10px;
    @media #{$tablet-up} {
      font-size: 1rem;
      padding: 8px 20px 10px;
    }
  }
}
</style>
