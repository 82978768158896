<template>
  <div class="confirmation-survey-progress">
    <GoalDonut
      class="confirmation-survey-progress-icon"
      :value="donutValue"
      :total="total"
      :content="{ color:'#50e3c2', bgcolor: colors.darkgray, formatter: (value, total) => { return '' }}" />
    <span class="confirmation-survey-progress-label"> {{ percent }}% completed</span>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import colors from '@/shared/assets/scss/colorsExport.scss';
  import GoalDonut from '@/shared/components/GoalDonut.vue';

  export default {
    name: 'Survey_Progress',
    components: {
      GoalDonut,
    },
    data: () => ({
      colors,
    }),
    computed: {
      ...mapGetters(['surveyCurrentSlide', 'surveyCurrentPos']),
      total() {
        return this.evaluateDeepness(this.surveyCurrentSlide, this.surveyCurrentPos) * 10;
      },
      donutValue() {
        return Math.floor(((this.surveyCurrentPos) * 10));
      },
      percent() {
        return Math.floor(this.donutValue / this.total * 100);
      },
    },

    methods: {
      evaluateDeepness(obj, current = 0) {
        if (obj.followup) {
          return this.evaluateDeepness(obj.followup, current + 1);
        }
        if (obj.options) {
          return obj.options.reduce((acc, option) => {
            if (option.followup) {
              const test = this.evaluateDeepness(option.followup, current + 1);
              return (acc > test) ? acc : test;
            }
            return acc;
          }, current + 1);
        }
        if (obj.type !== 'thankyou') {
          return current + 1;
        }
        return current;
      },
    },

  };
</script>

<style scoped lang="scss">
  .confirmation-survey {
    margin: 0 auto;
    max-width: 600px;
    text-align: center;
    width: 100%;

    &-progress {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-progress-icon {
      margin: 10px;
      height: 40px;
      width: 40px;
    }

    &-progress-label {
      margin: 10px;
    }

    &-question {
      margin: 20px 5px;
      font-size: 30px;
    }
  }
</style>
