<template>
  <modal
    name="BetaModal"
    :adaptive="true"
    :pivot-y="0.1"
    height="auto"
    width="80%"
    :max-width="700"
    :max-height="700"
    :clickToClose="false">
    <div
      class="close-button"
      v-track-click="`${$options.name}_CloseIcon`"
      @click="handleHide">
      <font-awesome-icon :icon="['fas', 'times']" />
    </div>
    <div class="beta-modal-content" v-track-view="$options.name">
      <h2 class="beta-modal-title">
        SSSSSSHHHHHH CAN YOU KEEP A SECRET?
      </h2>
      <img class="beta-modal__trainer-img" src="https://a.storyblok.com/f/152150/2208x1134/cac5c71d47/fc-trainers-shh.png">
      <p class="beta-modal__text">
        FightCamp is getting ready to release a hot one, but we need your help. <br>Do you want to be the first to try what we've been building <br> at NO additional cost?
      </p>
      <div class="beta-modal__btns-container">
        <ButtonAction
          class="beta-modal__cta beta-modal__skip"
          size="small"
          secondary
          @click.native="handleHide"
          v-track-click="`${$options.name}_Skip_Continue`">
          Nah I'm Good
        </ButtonAction>
        <NuxtLink
          class="beta-modal__cta beta-modal__apply fc-text-white button-layout button-primary button-small"
          to="/shop/beta"
          v-track-click="`${$options.name}_ApplyButton`">
          Give me the heat 🔥
        </NuxtLink>
      </div>
    </div>
  </modal>
</template>

<script>
import ButtonAction from '@/shared/components/ButtonAction.vue';
import ButtonLink from '@/shared/components/ButtonLink.vue';

export default {
  name: 'BetaModal',
  components: {
    ButtonAction,
    ButtonLink,
  },
  mounted() {
    this.$modal.show('BetaModal');

    document.body.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.handleHide();
      }
    })
  },
  methods: {
    handleHide() {
      this.$modal.hide('BetaModal');
      this.$gen2Api.decline(this.$store.getters.confirmation.email);
      this.$analytics.trackEvent('Gen2 Beta Declined', {
        orderNumber: this.$store.getters.confirmation.orderNumber,
        email: this.$store.getters.confirmation.email,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.beta-modal {
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-color: $color-white;
    text-align: center;
    padding: 2rem 0;
  }

  &-title {
    font-size: 1.5rem;
    text-align: center;
    color: $color-red;

    @media #{$tablet-up} {
      font-size: 3rem;
    }
  }

  &__text {
    font-size: 1.125rem;
    padding: 2rem 1rem 0;
    margin-top: 0;
    text-align: center;
  }

  &__btns-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    @media #{$tablet-up} {
      align-items: unset;
      flex-direction: row;
      gap: 2rem;
      margin: 2rem;
    }

    button {
      background-color: transparent;
      border: none;
      font-size: 1rem;
    }
  }

  &__cta {
    width: 250px;
    max-width: 250px;
    margin: 0;
  }
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  font-size: 1rem;
  cursor: pointer;

  svg path {
    fill: $color-blue;
  }
}
</style>
