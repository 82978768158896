<template>
  <SurveySlide
    :slide="slide"
    skippable
    @onSubmit="$emit('onSubmit', $event);">
    <div class="survey-slideselect">
      <ButtonAction
        v-for="(option, index) in getSurveryOptions"
        :key="option.id + index"
        :class="getButtonClass(option)"
        secondary
        @click.native="optionClicked(option)">
        {{ option.label }}
      </ButtonAction>
      <div v-if="showFollowup" class="survey-slideselect-followup">
        <div class="survey-slideselect-followup-form">
          <FCInput
            v-model="input"
            class="survey-slideselect-followup-input"
            type="text"
            :title="followupData.label"
            :focus="true"
            @onSubmit="followupSubmitted(followupData)" />
          <ButtonAction
            class="survey-slideselect-followup-button"
            @click.native="followupSubmitted(followupData)">
            Submit
          </ButtonAction>
        </div>
      </div>
    </div>
    <div v-if="canSubmit" class="survey-slideselect-next">
      <ButtonAction
        class="survey-slideselect-next-button"
        @click.native="onSubmit()">
        Next
      </ButtonAction>
    </div>
  </SurveySlide>
</template>
<script>
  import { mapActions } from 'vuex';
  import FCInput from '@/shared/components/FCInput.vue';
  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import SurveySlide from './Survey_Slide.vue';
  import randomizeOptions from '../../utils/surveryHelpers';

  export default {
    name: 'Survey_SlideMultiSelect',
    components: {
      FCInput,
      ButtonAction,
      SurveySlide,
    },
    props: {
      slide: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        input: '',
        selectedIds: [],
        showFollowup: false,
        followupData: {},
        maxOptions: this.slide.maxOptions || 3,
      };
    },
    computed: {
      canSubmit() {
        return this.selectedIds.length > 0;
      },
      getSurveryOptions() {
        return randomizeOptions(this.slide);
      },
    },
    methods: {
      // filter out
      ...mapActions(['createSurveyEvent']),
      followupSubmitted(option) {
        this.showFollowup = false;
        const id = `${option.id}: ${this.input}`;
        this.selectedIds.push(id);
        this.answerSubmitted(id);
      },
      optionClicked(option) {
        const { id, inlineFollowup } = option;
        if (inlineFollowup) {
          this.showFollowup = !this.showFollowup;
          this.followupData = {
            label: inlineFollowup.label,
            id,
          };
          return;
        }
        this.selectedIds.push(id);
        this.answerSubmitted(id);
      },
      answerSubmitted(id) {
        // Extend this if answers need to do more than track heap event
        this.createSurveyEvent({
          value: id,
          survey: this.slide.id,
          attribution: this.slide.attribution,
        });
        if (this.selectedIds.length === this.maxOptions) {
          this.onSubmit();
        }
      },
      getButtonClass(option) {
        const { id, inlineFollowup } = option;
        const prefix = 'survey-slideselect-option';
        return {
          [`${prefix}`]: true,
          [`${prefix}-selected`]: this.selectedIds.find(selectedId => selectedId.indexOf(id) !== -1),
          [`${prefix}-highlighted`]: inlineFollowup && this.showFollowup,
        };
      },
      getEventData() {
        return {
          id: `${this.slide.bypassId}`,
          others: this.slide.others,
          attribution: this.slide.attribution,
        };
      },
      onSubmit() {
        this.$emit('onSubmit', this.getEventData());
      },
      created() {
        console.log(this.slide.options);
      },
    },
  };
</script>
<style scoped lang="scss">
.survey-slideselect {
  max-width: 800px;
  width: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &-next {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    &-button {
      width: 90px;
    }
  }

  &-option {
    font-size: .75rem;
    padding: 8px 10px 10px;
    margin: 5px;
    opacity: 1;
    transition: opacity .3s ease;
    @media #{$tablet-up} {
      font-size: 1rem;
      padding: 8px 20px 10px;
    }
    &-selected {
      pointer-events: none;
      opacity: .3;
    }
    &-highlighted {
      background-color: $color-black;
      color: $color-white;
    }
  }
  &-followup {
    margin-top: 20px;
    width: 100%;
    &-form {
      max-width: 600px;
      display: flex;
      flex-flow: row wrap;
      margin: 0 auto;
    }
    &-input {
      width: calc(100% - 120px);
    }
    &-button {
      background-color: $color-red;
      border-color: $color-red;
    }
  }
}
</style>
