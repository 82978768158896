<template>
  <SurveySlide
    :slide="slide"
    skippable
    @onSubmit="$emit('onSubmit', $event);">
    <div class="survey-slidelist">
      <ButtonAction
        v-for="option in getSurveryOptions"
        :key="option.id"
        secondary
        class="survey-slidelist-option"
        @click.native="$emit('onSubmit', getEventData(option))">
        <img v-if="!!option.image" class="survey-slidelist__thumbnail" :src="option.image" :alt="option.label" />
        <div v-else class="survey-slidelist__label-container">
          <span>
            {{ option.label }}
          </span>
          <img class="survey-slidelist-chevron" src="@/shared/assets/chevron-right.svg" alt="right facing arrow icon">
        </div>
      </ButtonAction>
    </div>
  </SurveySlide>
</template>
<script>
  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import SurveySlide from './Survey_Slide.vue';
  import randomizeOptions from '../../utils/surveryHelpers';

  export default {
    name: 'Survey_SlideList',
    components: {
      ButtonAction,
      SurveySlide,
    },
    props: {
      slide: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      getEventData(option) {
        return {
          id: `${option.id}`,
          others: this.slide.others,
          attribution: option.attribution,
          followup: option.followup,
          value: option.image ? [`${this.slide.others}, ${option.id}`] : []
        };
      },
    },
    computed: {
      getSurveryOptions() {
        return randomizeOptions(this.slide);
      },
    },
  };
</script>
<style scoped lang="scss">

.survey-slidelist {
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  &-option {
    text-align: left;
    font-family: $font-stack-bold;
    padding: 10px 10px;
    margin: 5px 5px 10px;
    font-size: 16px;
    border-radius: $container-radius;
  }

  &__label-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &-chevron {
    height: 20px;

    &:hover {
      fill: white;
    }
  }

  &__thumbnail {
    width: 100%;
    margin: 1rem 0;
  }
}
</style>
