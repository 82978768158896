<template>
  <router-link
    :class="buttonStyle"
    :to="to">
    <slot />
  </router-link>
</template>
<script>
  import buttonStyleMixin from '../mixins/button-style';

  export default {
    name: 'ButtonRouter',
    mixins: [buttonStyleMixin],
    props: {
      to: { type: String, default: '/' },
    },

  };
</script>
