<template>
  <div class="survey-slide">
    <h3 class="survey-slide-question" v-html="formattedQuestion" />
    <slot />
    <a
      v-if="skippable"
      class="survey-slide-skip"
      @click="skip()">
      I do not remember
    </a>
  </div>
</template>
<script>
  const boldRegex = /\*([^*]+)\*/g;
  const allCapsRegex = /\^([^*]+)\^/g;

  export default {
    name: 'Survey_Slide',
    props: {
      slide: {
        type: Object,
        default: () => ({}),
      },
      skippable: Boolean,
    },
    computed: {
      formattedQuestion() {
        return this.slide.question
            .replace(boldRegex, '<strong style="text-decoration: underline">$1</strong>')
            .replace(allCapsRegex, '<span style="text-transform: uppercase">$1</span>');
      },
    },
    methods: {
      skip() {
        const id = this.slide.id ? `${this.slide.id} Skip` : 'Skip';
        this.$emit('onSubmit', {
          id,
          attribution: this.slide.attribution || '',
          others: this.slide.others,
          followup: this.slide.followup
        });
      },
    },
  };
</script>
<style scoped lang="scss">

.survey-slide {
  display: flex;
  flex-direction: column;

  &-question {
    line-height: 1.5;
    margin: 0 auto 10px;
    max-width: 320px;
    font-size: 24px;

    @media #{$tablet} {
      max-width: 100%;
      font-size: 30px;
    }

    @media #{$desktop} {
      max-width: 100%;
      font-size: 30px;
    }
  }

  &-skip {
    margin: 20px;
  }
}
</style>
