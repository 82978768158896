<template>
  <div class="confirmation-step">
    <div class="confirmation-step-content-row">
      <div class="confirmation-step-number-container">
        <h1 class="confirmation-step-number">
          {{ step }}
        </h1>
      </div>
      <h3>
        {{ title }}
      </h3>
      <slot name="block-content" />
    </div>
    <div class="confirmation-step-button-row">
      <slot name="block-button-row" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Confirmation_Step',
    props: {
      step: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style scoped lang="scss">
  .confirmation-step {
    flex: 0 1 310px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: $color_light_gray;
    border-radius: 5px;
    padding: 20px 10px;
    justify-content: space-between;

    h3 {
      text-transform: none;
      letter-spacing: 0;
      font-size: 16px;
    }

    &-number-container {
      height: 50px;
      width: 50px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      margin: 10px auto 0 auto;
      border: 3px solid black;
    }

    &-number {
      margin: 0;
      padding: 0 0 0 0;
      letter-spacing: 0;
      line-height: 0;
    }

    &-button-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
    }
  }
</style>
