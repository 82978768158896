<template>
  <SurveySlide
    :slide="slide"
    skippable
    @onSubmit="$emit('onSubmit', $event);">
    <div class="survey-slideinput">
      <FCInput
        ref="surveyInput"
        v-model="input"
        class="survey-slideinput-input"
        type="text"
        title="Custom answer"
        :focus="true"
        :class="{ bounce: survey_empty==true }"
        @onSubmit="submit()" />
      <ButtonAction
        class="survey-slideinput-button"
        @click.native="submit()">
        Submit
      </ButtonAction>
    </div>
  </SurveySlide>
</template>

<script>
  import FCInput from '@/shared/components/FCInput.vue';
  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import SurveySlide from './Survey_Slide.vue';

  export default {
    name: 'Survey_SlideInput',
    components: {
      ButtonAction,
      FCInput,
      SurveySlide,
    },
    props: {
      slide: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      input: '',
      survey_empty: false,
    }),
    computed: {
      lastId() {
        const arr = this.slide.id.split(' ');
        return arr[arr.length - 1];
      },
      others() {
        if (!this.slide.others) {
          return [`${this.lastId}: ${this.input}`];
        }
        return this.slide.others.concat([`${this.lastId}: ${this.input}`]);
      },
      eventData() {
        return {
          id: `${this.slide.id}`,
          others: this.others,
          attribution: this.slide.attribution,
          followup: this.slide.followup,
        };
      },
    },
    methods: {
      activateBounce() {
        this.survey_empty = true;
        setTimeout(() => {
          this.survey_empty = false;
        }, 500);
      },
      submit() {
        if (!this.input) {
          this.activateBounce();
          return;
        }
        this.$emit('onSubmit', this.eventData);
      },

    },
  };
</script>

<style scoped lang="scss">
  .survey-slideinput {
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;

    &-label {
      text-align: left;
    }

    &-input {
      margin: 10px 0 20px;
    }

    &-button {
      margin: 0 0;
    }

    &-skip {
      margin: 20px;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }

    20% {
      transform: translateY(-5px);
    }

    50% {
      transform: translateY(5px);
    }

    80% {
      transform: translateY(-5px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .bounce {
    animation: bounce 0.2s ease-in-out;
  }
</style>
