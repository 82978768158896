import { render, staticRenderFns } from "./Confirmation_Step.vue?vue&type=template&id=85669752&scoped=true&"
import script from "./Confirmation_Step.vue?vue&type=script&lang=js&"
export * from "./Confirmation_Step.vue?vue&type=script&lang=js&"
import style0 from "./Confirmation_Step.vue?vue&type=style&index=0&id=85669752&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85669752",
  null
  
)

export default component.exports