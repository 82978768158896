<template>
  <div class="feature-row-container">
    <div class="feature-row-block">
      <img class="image-help" src="@/shared/assets/help.png">
      <h1 v-track-view="$options.name">
        Need Anything Else?
      </h1>
      <p>
        Our Customer Success Team is dedicated to ensuring that your FightCamp Journey is successful. If you have
        any questions
        or need any assistance along the way, please don't hesitate to reach out to us! If you have a question or
        need
        assistance right now, please hit the "Submit a request" button below.
      </p>
      <ButtonLink
        primary
        v-track-click="$options.name + '_Request'"
        to="https://support.joinfightcamp.com/hc/en-us">
        Submit a request
      </ButtonLink>
    </div>
  </div>
</template>

<script>
  import ButtonLink from '@/shared/components/ButtonLink.vue';

  export default {
    name: 'Confirmation_Support',
    components: {
      ButtonLink,
    },
  };
</script>

<style scoped lang="scss">

  .image-help {
    height: 75px;
  }

  .btn2 {
    box-sizing: border-box;
    background-color: $color-accent;
    color: #fff;
    font-size: 14px;
    padding: 12px;
    font-family: $font-stack-bold;
    letter-spacing: 1px;
    float: right;
    cursor: pointer;
    border-radius: 50px;
    border: none;
    margin: 30px 0;
  }

  .feature-row-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }

  @media (min-width: 600px) {
    .feature-row-container {
      margin: 50px 0;
    }
  }

  .feature-row-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 1 500px;
    text-align: center;
    margin: 0 5px;
    padding: 0 10px;
  }

  .feature-row-block ol {
    margin: 0;
  }
</style>
