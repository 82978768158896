<template>
  <div>
    <div
      v-if="show_subs_content"
      id="postpurchase"
      class="checkout-postpurchase-container"
      :class="{ 'checkout-postpurchase-container__active': true}">
      <h1 class="fc-text-blue fc-font-size-20 fc-margin-top-10 fc-margin-bottom-3 fc-text-center">
        Welcome to FightCamp!
      </h1>
      <h3 v-if="orderNumber" class="order-text fc-text-center">
        Your order number is <strong class="fc-text-red">#{{ orderNumber }}</strong>
      </h3>
      <h3 v-if="orderNumber" class="fc-text-center fc-margin-top-4 fc-margin-bottom-0 fc-padding-x-4 md:fc-padding-x-0">
        Your membership will begin after you receive your equipment.
      </h3>
      <h3 v-show="showUpgradeForm && orderNumber" class="fc-text-center fc-margin-top-4 fc-margin-bottom-0 fc-padding-x-4 md:fc-padding-x-0">
        You are currently on the $39/mo monthly plan. Confirm your membership type below:
      </h3>
      <h3 v-show="upgradeSuccessful && orderNumber" class="order-text">
        <strong>Thank you for upgrading your membership!</strong>
      </h3>
      <transition name="fade">
        <div v-if="!isPos">
          <SubUpgrade v-show="showUpgradeForm" @triggerShowSteps="triggerShowSteps" />
        </div>
      </transition>
      <transition name="fade">
        <Survey
          message="One last step..."
          :survey="hdyhau"
          v-if="upgradeFormComplete && !hasGQProduct && !hasGen2BundleUpgrade" />
      </transition>
        <transition name="fade">
          <Steps v-if="surveydone && upgradeFormComplete" :url="url" />
        </transition>
        <transition name="fade">
          <Support v-if="surveydone && upgradeFormComplete" />
        </transition>
    </div>
    <div
      v-else
      class="checkout-postpurchase-container"
      :class="{ 'checkout-postpurchase-container__active': true}">
      <h1 class="checkout-postpurchase-title">
        Thank you!
      </h1>
      <h3 v-if="orderNumber" class="order-text fc-text-center">
        Your order number is #{{ orderNumber }}
      </h3>
      <h3 class="order-text">
        You will receive a receipt by email soon!
      </h3>
      <!-- Checkout Container -->
      <div class="summary">
        <div class="summary-panel">
          <h2> Order Summary </h2>
          <Cart class="cart" :cart-content="cartContent" :isShopifyOn="isShopifyOn" />
        </div>
      </div>
      <Support />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Cart from '@/shared/components/cart/CartFrame.vue';
  import Survey from '@/shared/components/survey/Survey.vue';
  import heardAboutUs from '@/shared/datasets/hdyhau';
  import Steps from './Confirmation_Steps.vue';
  import Support from './Confirmation_Support.vue';
  import SubUpgrade from './Confirmation_SubUpgrade.vue';

  const TEN_SECONDS = 10000;

  const isShopifyOn = process.env.NUXT_ENV_SHOPIFY_ON === 'true';

  export default {
    name: 'Confirmation',
    components: {
      Cart,
      Survey,
      Steps,
      Support,
      SubUpgrade,
    },
    props: {
      url: {
        type: String,
        default: undefined,
      },
    },
    data: () => ({
      hdyhau: heardAboutUs,
      show_subs_content: false,
      showSteps: false,
      isShopifyOn,
    }),
    computed: {
      ...mapGetters(['confirmation', 'order', 'productsCollection', 'getProductVariantById']),
      isPos() {
        return this.$store.state.app.location === 'InsideSales';
      },
      hasHoliday() {
        const { cart } = this.confirmation || {};
        if (this.isShopifyOn && !this.hasGQProduct) {
          const cartData = cart.map(item =>  this.getProductVariantById(item.id) ?? {});
          return cartData.find(item => item.variants.some(variant => variant.uid.startsWith('fightcamp_all_in_one_bundle')));
        }

        return (cart || []).find(item => item.id.startsWith('fightcamp_holiday_bundle'));
      },
      hasAnnualMembership() {
        const membershipProduct = this.confirmation?.subscription ?? {};
        return membershipProduct?.tags?.includes('annual-membership') ?? false;
      },
      hasGQProduct() {
        return !!this.confirmation.cart.find(item => item.id === 'gq_fightcamp_trackers_S' || item.id === 'gq_fightcamp_trackers_L');
      },
      hasGen2BundleUpgrade() {
        const { cart } = this.confirmation || {};

        const cartData = cart.map(item =>  this.getProductVariantById(item.id) ?? {});
        return cartData.find(item => item.variants.some(variant => variant.uid.startsWith('console_trackers_annual_bundle')));
      },
      showUpgradeForm() {
        if (this.hasHoliday || this.hasGQProduct || this.hasGen2BundleUpgrade || this.hasAnnualMembership) {
          return false;
        }

        return !this.surveydone && !this.showSteps && !this.upgradeFormComplete;
      },
      upgradeFormComplete() {
        if (this.hasHoliday || this.hasGQProduct || this.hasGen2BundleUpgrade || this.hasAnnualMembership) {
          return true;
        }

        return this.$store.state.survey.upgradeFormCompleted;
      },
      surveydone() {
        return this.$store.state.survey.completed || this.hasGQProduct || this.hasGen2BundleUpgrade;
      },
      upgradeSuccessful() {
        return this.$store.state.survey.upgradeSuccessful;
      },
      orderNumber() {
        if (!this.confirmation) {
          return null;
        }
        return this.confirmation.orderNumber;
      },
      email() {
        if (!this.confirmation) {
          return null;
        }
        return this.confirmation.email;
      },
      uid() {
        if (!this.confirmation) {
          return null;
        }
        return this.confirmation.uid;
      },
      orderId() {
        if (!this.confirmation) {
          return null;
        }
        return this.confirmation.orderID;
      },
      totalCost() {
        if (!this.order) {
          return 0;
        }
        return this.order.costBreakdown.totalCost / 100;
      },
      cartContent() {
        let { cart, order } = this.confirmation || {}
        const { costBreakdown } = order || {}
        const {
          productCost: subtotal,
          productShipping: shipping,
          productTax: tax,
          discountAmount: coupon,
          totalCost: total,
        } = costBreakdown || {}

        if (this.isShopifyOn && !this.hasGQProduct) {
          cart = cart?.map(item => {
            const productData = this.getProductVariantById(item.id);
            const productVariant = productData?.variants.find(variant => variant?.id === item?.id) || {};
            const title = productVariant?.name === 'Default Title' ? productData?.title : productVariant?.name;

            return {
              ...productData,
              id: productVariant?.uid,
              price: productVariant?.price,
              productName: title,
              description: productData.description,
              qty: item.qty,
            };
          });
        }

        return {
          cart,
          subtotal,
          shipping,
          tax,
          coupon,
          total,
        };
      },
    },
    methods: {
      triggerShowSteps() {
        this.showSteps = true;
      },
    },
    created() {
      this.$store.dispatch('clearCart');

      this.prod = process.env.VUE_APP_ANALYTICS_ENV !== 'dev';
      const { cart, orderNumber, subscription } = this.confirmation || {}

      const found = (cart || []).find(item => item.type === 'subs' || item.type === 'subs_only');

      this.show_subs_content = (found != null) || subscription;

      if (orderNumber && !this.seenConfirmation) {
        this.seenConfirmation = true;
      }

      if (this.show_subs_content) {
        this.$store.commit('SET_ORDER_INFO', {
          email: this.email,
          orderNumber: this.orderNumber, // must be Number or else survey API throws an error
          orderId: this.orderId,
          uid: this.uid,
          skipSurveyEvent: !!(this.hasGQProduct || this.hasGen2BundleUpgrade)
        });
      }
    },
  };

</script>

<style scoped lang="scss">
  h3 {
    text-transform: none;
    letter-spacing: 0;
    font-size: 16px;
  }

  .checkout-postpurchase-container {
    display: none;
    width: 100%;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
  }

  .checkout-postpurchase-container__active {
    display: flex;
  }

  .checkout-postpurchase-title {
    text-align: center;
    font-size: 30px;
    margin-bottom: 0;
    text-transform: none;
  }

  @media (min-width: 600px) {
    .checkout-postpurchase-title {
      text-align: center;
      padding: 40px 0 0;
      margin-bottom: 10px;
      font-size: 40px;
    }
  }

  @media (min-width: 800px) {
    .checkout-postpurchase-title {
      text-align: center;
      padding: 40px 0 0;
      margin-bottom: 10px;
      font-size: 60px;
    }
  }

  .subtitle {
    margin: 10px 0 5px 0;
  }

  .order-text {
    text-align: center;
    padding: 0 0 0;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .summary {
    margin-top: 30px;
    box-sizing: border-box;
    padding: 14px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .summary-panel {
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
</style>
