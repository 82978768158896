<template>
  <div class="confirmation-steps" v-track-view="$options.name">
    <ConfirmationStep step="1" title="Your order is on the way">
      <template v-slot:block-content>
        <img
          class="confirmation-steps__img1"
          src="@/shared/assets/truck-shipped_filled.png">
        <p>
          Your order will ship soon. <br />
          Your order has been successfully processed. You will receive an email
          with updated shipping details.
        </p>
      </template>
    </ConfirmationStep>
    <ConfirmationStep step="2" title="Install the FightCamp App">
      <template v-slot:block-content>
        <a
          class="confirmation-steps__link--apple-store"
          :href="url"
          target="_blank"
          rel="noopener"
          v-track-click="$options.name + '_AppleStore'">
          <img
            class="confirmation-steps__img2--apple"
            alt="Download on the App Store Badge"
            src="@/shared/assets/appstore.svg">
        </a>
        <a
          class="confirmation-steps__link--google-play"
          href="https://play.google.com/store/apps/details?id=com.fightcamp.android"
          target="_blank"
          rel="noopener"
          v-track-click="$options.name + '_GooglePlay'">
          <img
            class="confirmation-steps__img2--google"
            alt="Download on the Google Play Badge"
            src="https://cdn3.joinfightcamp.com/v4/fc-google-play-icon-001.png">
        </a>
        <p>
          FightCamp is available on the App Store and on Google Play.
          <strong>
            Alternatively, we can simply text the link to your phone.
          </strong>
        </p>
      </template>
      <template v-slot:block-button-row>
        <div class="confirmation-steps__sms-container fc-flex fc-row">
          <input
            v-model="textPhoneNumber"
            class="confirmation-steps__sms-input"
            type="text"
            name="shippingPhoneNumber"
            placeholder="Phone Number*"
          />
          <ButtonAction
            primary
            size="small"
            class="confirmation-steps__sms-button"
            v-track-click="$options.name + '_SendSMS'"
            @click.native="textApp()"
          >
            <span
              v-show="textAppState === 0"
              class="confirmation-steps__btn2_message fc-bold"
              >Text me the app</span
            >
            <div
              v-show="textAppState === 1"
              class="confirmation-steps__btn2_message"
            >
              <font-awesome-icon :icon="['fas', 'circle-notch']" spin />
              Processing...
            </div>
            <div
              v-show="textAppState === 2"
              class="confirmation-steps__btn2_message"
            >
              <font-awesome-icon :icon="['fas', 'check-circle']" /> SMS Sent
            </div>
            <div
              v-show="textAppState === 3"
              class="confirmation-steps__btn2_message"
            >
              <font-awesome-icon :icon="['fas', 'times-circle']" /> Failed
            </div>
          </ButtonAction>
        </div>
      </template>
    </ConfirmationStep>
    <ConfirmationStep step="3" title="Save Tracker Activation Code">
      <template v-slot:block-content>
        <img
          class="confirmation-steps__img3"
          src="@/shared/assets/qr-icon.svg"
        />
        <p>
          You will receive an email with you Tracker Activation Code. When your
          punch tracker arrives, use this code to activate your trackers and get
          started with FightCamp.
        </p>
        <p>Share this code with up to 4 members of your household.</p>
      </template>
    </ConfirmationStep>
    <ConfirmationStep step="4" title="Join the Community">
      <template v-slot:block-content>
        <img
          class="confirmation-steps__img4"
          src="@/shared/assets/chat_filled.png"
        />
        <p>
          Join the FightCamp Community on Facebook and follow us on Instagram to
          stay up-to-date on the latest FightCamp News!
        </p>
      </template>
      <template v-slot:block-button-row>
        <div class="confirmation-steps--cta-container">
          <ButtonLink
            primary
            size="small"
            to="https://www.facebook.com/groups/542530602799015/"
            v-track-click="$options.name + '_FacebookCommunity'"
          >
            <font-awesome-icon
              class="confirmation-steps__button-icon"
              :icon="['fab', 'facebook-square']"
            />
            Facebook
          </ButtonLink>
        </div>

        <div class="confirmation-steps--cta-container">
          <ButtonLink
            primary
            size="small"
            to="https://www.instagram.com/fightcamp"
            v-track-click="$options.name + '_Instagram'"
          >
            <font-awesome-icon
              class="confirmation-steps__button-icon"
              :icon="['fab', 'instagram']"
            />
            Instagram
          </ButtonLink>
        </div>
      </template>
    </ConfirmationStep>
    <ConfirmationStep step="5" title="Watch our YouTube Channel">
      <template v-slot:block-content>
        <img
          class="confirmation-steps__img5"
          src="@/shared/assets/yt_logo_light.png"
        />
        <p>
          Get to know your trainers as they get you motivated and help you
          improve technique. Subscribe to catch our weekly uploads, and give us
          feedback in the comments.
        </p>
      </template>
      <template v-slot:block-button-row>
        <div>
          <ButtonLink
            primary
            size="small"
            to="https://www.youtube.com/channel/UCJ6J1r5u8CFsbyVZ03A6IaQ"
            v-track-click="$options.name + '_YoutubeChannel'"
          >
            <font-awesome-icon
              class="confirmation-steps__button-icon"
              :icon="['fab', 'youtube']"
            />
            YouTube
          </ButtonLink>
        </div>
      </template>
    </ConfirmationStep>
    <ConfirmationStep step="6" title="Shop FightCamp Apparel">
      <template v-slot:block-content>
        <font-awesome-icon :icon="['fc', 'shirt']" class="confirmation-steps__img6"/>
        <p>
          Show off your inner fighter & start your boxing training in style with
          FightCamp apparel, designed to fit perfectly in the gym or at home.
          Check out #WearFightCamp to see how our community & trainers are
          gearing up.
        </p>
      </template>
      <template v-slot:block-button-row>
        <div>
          <ButtonLink
            primary
            size="small"
            to="https://apparel.joinfightcamp.com/collections/all?utm_source=website&utm_medium=postpurchase&utm_content=apparel&utm_campaign="
            v-track-click="$options.name + '_FightcampApparel'"
          >
            Shop Now
          </ButtonLink>
        </div>
      </template>
    </ConfirmationStep>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import ButtonLink from '@/shared/components/ButtonLink.vue';
  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import ConfirmationStep from './Confirmation_Step.vue';

  const TEN_SECONDS = 10000;

  export default {
    name: 'Confirmation_Steps',
    components: {
      ButtonAction,
      ButtonLink,
      ConfirmationStep,
    },
    props: {
      url: {
        type: String,
        default: undefined,
      }
    },
    data: () => ({
      textAppState: 0,
      textPhoneNumber: '',
    }),
    computed: {
      ...mapGetters(['confirmation']),
    },
    created() {
      if (this.confirmation) {
        this.textPhoneNumber = this.confirmation.textPhoneNumber;
      }
    },
    methods: {
      textApp() {
        if (this.textAppState === 0) {
          this.textAppState = 1;
          this.$api_client.textApp(this.textPhoneNumber, this.$props.url)
            .then((rep) => {
              this.textAppState = 2;
            }).catch((err) => {
              this.textAppState = 3;
            });
        } else if (this.textAppState > 1) {
          this.textAppState = 0;
        }
      },
    },
  };
</script>
<style scoped lang="scss">
  @import '@fc/angie-ui/dist/styles/exports';

  .confirmation-steps {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: center;
    margin: get-spacing-level(9) auto;

    &__sms-container input {
      background-color: #fff;
      box-sizing: border-box;
      border: 1px solid #e4e6e7;
      font-size: 16px;
      padding: get-spacing-level(2) get-spacing-level(2) get-spacing-level(2) get-spacing-level(4);
      margin: get-spacing-level(2) get-spacing-level(0);
      color: #000;
      width: 150px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &__sms-button {
      padding: get-spacing-level(2) get-spacing-level(2) get-spacing-level(3) get-spacing-level(2);
      margin: get-spacing-level(2) 0;
      margin-left: -20px;
    }

    &__img1 {
      height: 75px;
      margin: get-spacing-level(3) 0;
    }

    &__img2 {
      &--apple {
        height: 55px;
      }

      &--google {
        height: 75px;
      }
    }

    &__img3 {
      height: 55px;
      margin: get-spacing-level(5) 0;
    }

    &__img4 {
      height: 65px;
      margin: get-spacing-level(4) get-spacing-level(0);
    }

    &__img5 {
      height: 40px;
      margin: get-spacing-level(6) 0;
    }

    &__img6 {
      height: 4rem;
      margin: get-spacing-level(3) 0;
    }

    &__button-icon {
      margin-right: get-spacing-level(1);
    }

    &--cta-container {
      width: 45%;
    }

    &__link {
      &--apple-store,
      &--google-play {
        line-height: 0;
        display: inline-block;
      }

      &--apple-store {
        margin: get-spacing-level(5) 0;
      }
    }
  }
</style>
